import {Http} from "../../Http";
import {SET_CONFIG} from "./actionTypes";

export function loadConfig() {
  return async (dispatch) => {
    const response = await Http.get(`/config.json`);
    dispatch(setConfig(response));
  }
}

export function setConfig(value) {
  return {
    type: SET_CONFIG,
    payload: value
  }
}