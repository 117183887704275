import React, {useState} from 'react'
import "../css/main.css"
import {EDS} from "./eds";
import {OneTimePassword} from "./oneTimePassword";

export const TabBar = (props) =>  {
  const [componentTab, setComponentTab] = useState({
    tabs: [
      {
        text: 'ЭЦП',
        key: 0,
        selected: true,
        component: <EDS />
      },
      {
        text: 'Одноразовый пароль',
        key: 1,
        selected: false,
        component: <OneTimePassword />
      }
    ],
    current: 0
  })
  
  const handleClick = (e) => {
    console.log(props);
    if(e !== componentTab.current) {
      let tabs = componentTab.tabs;
      tabs[e].selected = true;
      tabs[componentTab.current].selected = false;
      
      setComponentTab({ tabs: tabs, current: e })
    }
  }
  
  return (
    <div className="tab">
      {
        componentTab.tabs.map(tab => {
          return (
            <button
              key={tab.key}
              className="tablinks"
              onClick={() => handleClick(tab.key)}
            >
              <p>{tab.text}</p>
            </button>
          )
        })
      }
      <div className="tabContent">
        {componentTab.tabs[componentTab.current].component}
      </div>
    </div>
  )
}