import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ErrorPage } from './pages/Error';
import { Logout } from './pages/Logout';
import Login from "./pages/Login";
import {Suspense} from "react";
import "./i18n"
import {Sms} from "./components/sms";

function App() {
  return (
    <Suspense fallback="loading">
      <Router>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path="/sms-check">
            <Sms />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App;
