import React, {useEffect, useState} from 'react';
import '../css/main.css'
import {OneTimePassword} from "./oneTimePassword";
import {useHistory, useLocation} from "react-router-dom";
import {AppHeader} from "./toolbar";
import {loadConfig} from "../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";
import {Http} from "../Http";

export const Sms = (props) => {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const config = useSelector((state) => state.app.config);
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  
  const sendCode = () => {
    Http.post(`${config.url}/api/AuthOneTimePassword/CheckCode`, {
      Phone: location.state.phone,
      Code: code,
      ReturnUrl: location.state.returnUrl,
      UserName: location.state.userName
    }).then(() => {
     window.location.href = location.state.returnUrl;
    }).catch(err => {
        err.then(res => {
          setErrorMessage(res);
        })
      });
  }
  
  useEffect(() => {
    if (!config) {
      dispatch(loadConfig());
    }
  })

  const comeBack = () => {
    return history.goBack();
  }
  
  return (
    <div>
      <AppHeader />
      <div className="App">
        <div className="form">
          <div className="tabContent">
            <form className="form-group">
              <p>Введите проверочный код</p>
              <input
                type="text"
                maxLength="6"
                className="simple-input"
                value={code}
                onChange={e => setCode(e.target.value)}
              />
              <p className="error">{errorMessage}</p>
            </form>
            <button onClick={sendCode} className="sign-btn" id="oneTimePasswordButton">
              <span>Войти</span>
            </button>
            <a onClick={comeBack}>Указать другой номер телефона</a>
          </div>
        </div>
      </div>
    </div>
  );
};