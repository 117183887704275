import React, {useEffect, useState} from 'react'
import "../css/main.css"
import {useDispatch, useSelector} from "react-redux";
import {loadConfig} from "../redux/actions/actions";
import {useTranslation} from "react-i18next";
import {NcaLayer} from "../NCALayer";
import {Http} from "../Http";
import Cookies from 'js-cookie';

const ncaInstance = new NcaLayer();

export function EDS() {
  const [fio, setFio] = useState('');
  const [iin, setIin] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("ru");
  const [certStore, setCertStore] = useState('PKCS12');
  const config = useSelector((state) => state.app.config);
  const dispatch = useDispatch();
  
  useEffect(async() => {
    if (!config) {
      console.log(Cookies.get('culture'));
      dispatch(loadConfig());
      setCurrentLanguage(Cookies.get('culture')?.toLowerCase() ?? 'ru');
    }
  })

  const selectCert = () => {
    setIsDisabledBtn(true);
    
    Http.get(`${config.url}/api/auth/generateToken`).then(generatedToken => {
      ncaInstance.signXml(certStore, 'AUTH', `<data>${generatedToken.token}</data>`, data => {
        const xml = data.getResponseObject();
        if (xml) {
          Http.post(`${config.url}/api/auth/certInfo`, {
            Xml: xml
          }).then(data => {
            const certInfo = JSON.parse(data);
            return Http.get(`${config.url}/api/auth/hasUser/${certInfo.serialNumber}`).then(response => {
              setFio(certInfo.fullName);
              setIin(certInfo.serialNumber);
              sign(certInfo.serialNumber, xml, certInfo.fullName, "");
            }).catch(err => {
              err.then(res => {
                setErrorMessage(res);
                setIsDisabledBtn(false);
              })
            });
          }).catch(err => {
            err.then(res => {
              setErrorMessage(res);
              setIsDisabledBtn(false);
            })
          });
        }
      });
    });
  }

  const sign = (userName, xml, fullName, newEmail) => {
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('ReturnUrl');
    Http.post(`${config.url}/api/auth/`, {
      userName: (userName),
      password: 'random',
      email: (newEmail),
      signedXml: xml,
      fullName: (fullName),
      returnUrl: url
    })
      .then(res => {
        const data = JSON.parse(res);
        if (data && data.isOk) {
          window.location = data.redirectUrl;
        }
      }).catch(err => {
        err.then(res => {
          setErrorMessage(res);
          setIsDisabledBtn(false);
        })
    });
  };


  const { t } = useTranslation();
  
  return (
    <div>
      <h3>{t[currentLanguage]?.title}</h3>
      <div className="error">{t('warning')}</div>
      <form className="form-group">
        <input className="simple-input" disabled value={iin}/>
        <input className="simple-input" disabled value={fio}/>
        <p className="error">{errorMessage}</p>
      </form>
      <button 
        className="sign-btn"
        onClick={selectCert}
        disabled={isDisabledBtn}
      >{t('selectCert')}</button>
    </div>
  )
}