export class Http {
  static async post(url, data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    
    if(response.ok) {
      return await response.text();
    } else {
      return await Promise.reject(response.text());
    }
  }

  static async get(url) {
    const responce = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });

    return await responce.json();
  }
  
  static async logout(url) {
    const responce = await fetch(url, {
      credentials: 'include'
    });
    return await responce.json();
  }

  static async getText(url) {
    const responce = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });

    return await responce.text();
  }
}