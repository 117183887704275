import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {loadConfig} from "../redux/actions/actions";
import {Http} from "../Http";
import {useHistory} from "react-router-dom";

export const OneTimePassword = () => {
  const [iin, setIin] = useState('');
  const [phone, setPhone] = useState('');
  const [returnUrl, setReturnUrl] = useState('');
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const config = useSelector((state) => state.app.config);
  let history = useHistory();

  useEffect(() => {
    if (!config) {
      dispatch(loadConfig());
    }

    if (config) {
      const urlParams = new URLSearchParams(window.location.search);
      setReturnUrl(urlParams.get('ReturnUrl'));
    }
  })


  const phoneCheck = () => {
    setIsDisabledBtn(true);
    if(iin.length === 12 && phone.length !== 0) {
      Http.post(`${config.url}/api/AuthOneTimePassword/SendingSMS`, { Xin: iin, Phone: phone })
        .then(result => {
          history.push({
            pathname: '/sms-check',
            state: {
              phone,
              userName: iin,
              returnUrl
            }
          });
        })
        .catch(err => {
          err.then(res => {
            setErrorMessage(res);
            setIsDisabledBtn(false);
          })
        });
    }

  }
  
  return (
    <div>
      <form className="form-group">
        <h4 style={{width: '100%', display: 'flex', margin: 'auto'}}>ИИН*</h4>
        <input
          type="text"
          className="simple-input"
          maxLength="12"
          value={iin}
          onChange={e => setIin(e.target.value)}
        />
      </form>
      <form className="form-group">
        <h4 style={{width: '100%', display: 'flex', margin: 'auto'}}>Номер телефона*</h4>
        <input
          type="text"
          className="simple-input"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
      </form>
      <p className="error">{errorMessage}</p>
      <button
        onClick={phoneCheck}
        className="sign-btn"
        id="oneTimePasswordButton">
        <span>Войти</span>
      </button>
    </div>
  )
}