import {useEffect, useState} from "react"
import {Http} from "../Http";
import {loadConfig} from "../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";

export function Logout(props) {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const dispatch = useDispatch();
  const config = useSelector((state) => state.app.config);

  useEffect(async () => {
    if (!config) {
      dispatch(loadConfig());
    }

    if (config) {
      const urlParams = new URLSearchParams(window.location.search);
      const logoutId = urlParams.get('logoutId');

      Http.logout(`${config.url}/api/auth/logout?logoutId=${logoutId}`).then(data => {
        if (data.postLogoutRedirectUri) {
          setRedirectUrl(data.postLogoutRedirectUri);
          setTimeout(() => {
            window.location = data.postLogoutRedirectUri;
          }, 2000);
        }
      });
    }
  });

  return (
    <div>
      <h1>Вы успешно вышли из системы {redirectUrl}</h1>
    </div>
  )
}