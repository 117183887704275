import { useEffect, useState } from "react";
import { Http } from "../Http"
import {useDispatch, useSelector} from "react-redux";
import {loadConfig} from "../redux/actions/actions";

const errorsMap = {
	unauthorized_client: "Клиент не авторизирован",
	invalid_scope: "Не верно указан параметр 'scopes'"
}

export function ErrorPage(props) {
	const [error, setError] = useState(undefined);
	const config = useSelector((state) => state.app.config);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!config) {
			dispatch(loadConfig());
		}
		
		if (config && !error) {
			const urlParams = new URLSearchParams(window.location.search);
			const errorId = urlParams.get('errorId');
			Http.get(`${config.url}/api/auth/error?errorId=${errorId}`)
				.then(err => {
					setError(err);
				});
		}
	})



	console.log(props.location?.query);
	return (
		<div>
			<h1>Error</h1>
			<p>
				Произошла ошибка: {errorsMap[error?.error]}<br />
				Описание: {error?.errorDescription}
			</p>
		</div>
	)
}