import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './translations/ru.json'
import kk from './translations/kk.json'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    debug: true,
    resources: {
      ru: {
        translation: ru
      },
      kk: {
        translation: kk
      },
    },
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;