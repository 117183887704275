import React, {useState} from 'react'
import "../css/main.css"
import {useTranslation} from "react-i18next";

export function AppHeader() {
  const [currentLang, setCurrentLang] = useState('ru');
  const {i18n} = useTranslation('common');
  
  
  return (
    <div className="App-header">
      <button className="lang-btn" onClick={() => i18n.changeLanguage('ru')}>
        <span>RU</span>
      </button>
      <button className="lang-btn" onClick={() => i18n.changeLanguage('kk')}>
        <span>KK</span>
      </button>
    </div>
  )
}