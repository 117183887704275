import {GET_CONFIG, SET_CONFIG} from "../actions/actionTypes";

const initialState = {
  config: undefined
}


export default function appReduce(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIG: {
      return {
        ...state,
        config: {
          ...action.payload
        }
      }
    }
    default:
      return state;
  }
}