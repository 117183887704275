import {NcaLayer} from '../NCALayer';
import {AppHeader} from "../components/toolbar";
import "../css/main.css"
import {TabBar} from "../components/tabBar";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {loadConfig} from "../redux/actions/actions";
import {useDispatch, useSelector} from "react-redux";

export default function Login(props) {
  const [currentLanguage, setCurrentLanguage] = useState("ru");
  const config = useSelector((state) => state.app.config);
  const dispatch = useDispatch();
  
  useEffect(async() => {
    if (!config) {
      console.log(Cookies.get('culture')?.toLowerCase());
      dispatch(loadConfig());
      setCurrentLanguage(Cookies.get('culture')?.toLowerCase() ?? 'ru');
    }
  })
  
return (
	<div>
    <AppHeader/>
    <div className="App">
      <div className="form">
        <TabBar props={props}/>
      </div>
    </div>
  </div>
);
}
